import type { CSSProperties, ReactNode } from "react";

import { Link } from "@remix-run/react";

import { buttonVariants } from "~/components/ui/button";
import { Typography } from "~/components/ui/typography";
import { cn } from "~/lib/utils";
import { CLOUDINARY_IMAGE_URL } from "~/utils/constants";
import {
  ABOUT_PATH,
  PRIVACY_POLICY_PATH,
  TALK_TO_US_URL,
  TERMS_OF_SERVICE_PATH,
} from "~/utils/paths";

import { EmailCapture } from "./email-capture";

const FooterLink = (props: { children: ReactNode; to: string }) => (
  <Link className="group px-4 py-2" to={props.to}>
    <span className="flex items-center justify-center rounded-xl px-2 py-1 text-sm font-semibold text-white group-hover:bg-neutral-100/20 dark:group-hover:bg-neutral-500/70">
      {props.children}
    </span>
  </Link>
);

const style = {
  backgroundImage: `url(${CLOUDINARY_IMAGE_URL}/f_auto,q_auto/v1/backgrounds/oz7rdwajteucok4nwedo)`,
  backgroundPosition: "50% 46%",
} satisfies CSSProperties;

const iframeStyle = { colorScheme: "none" } satisfies CSSProperties;

export function Footer() {
  return (
    <>
      <div
        className="relative mx-auto flex min-h-screen max-w-screen-3xl flex-col items-center justify-center bg-cover bg-no-repeat py-96 text-center 3xl:rounded-xl"
        style={style}
      >
        <Typography as="h2" className="mb-6 text-white" variant="h1">
          Start making better decisions <br />
          today with Conveo
        </Typography>
        <div className="mb-7 flex w-full max-w-lg flex-col items-center gap-4 px-4 sm:flex-row">
          <Link
            className={cn(
              buttonVariants({ size: "xl" }),
              "text-base font-bold text-white",
            )}
            to={TALK_TO_US_URL}
          >
            See a Demo
          </Link>
          <EmailCapture />
        </div>
        <footer className="absolute inset-x-0 bottom-0 z-10 flex flex-row flex-wrap items-center justify-center whitespace-nowrap border-t border-t-white/30 bg-gradient-to-b from-transparent to-black/10 py-6 backdrop-blur">
          <Typography
            className="px-4 py-2 font-semibold text-white"
            variant="smallText"
          >
            © {new Date().getFullYear()} Conveo
          </Typography>
          <FooterLink to={ABOUT_PATH}>About us</FooterLink>
          <FooterLink to={PRIVACY_POLICY_PATH}>Privacy policy</FooterLink>
          <FooterLink to={TERMS_OF_SERVICE_PATH}>Terms of service</FooterLink>
          <FooterLink to="https://trust.conveo.ai">Trust center</FooterLink>
          <iframe
            className="my-2 px-4"
            height="30"
            src="https://status.conveo.ai/badge?theme=dark"
            style={iframeStyle}
            title="Conveo status"
            width="232"
          />
        </footer>
      </div>
    </>
  );
}
