import { ArrowRight } from "@phosphor-icons/react";
import { useFetcher } from "@remix-run/react";

import type { action } from "~/routes/api+/email-capture";

import { EMAIL_CAPTURE_PATH } from "~/utils/paths";

import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Typography } from "../ui/typography";

export function EmailCapture() {
  const fetcher = useFetcher<typeof action>();
  return fetcher.data?.success === true ? (
    <div className="flex h-12 items-center">
      <Typography className="font-semibold text-card">
        Thanks for subscribing!
      </Typography>
    </div>
  ) : (
    <fetcher.Form
      action={EMAIL_CAPTURE_PATH}
      className="flex w-full max-w-sm items-center gap-2"
      method="post"
    >
      <Input
        className="h-12 rounded-lg border-none bg-card/80 px-4 backdrop-blur placeholder:text-secondary-foreground/70"
        name="email"
        placeholder="✉️ or stay updated"
        required
        type="email"
      />
      <Button size="icon" type="submit" variant="ghost">
        <ArrowRight
          className="text-card hover:text-card-foreground"
          size={32}
          weight="bold"
        />
      </Button>
    </fetcher.Form>
  );
}
